import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ArchivePage = ({ location }) => (
  <Layout location={location} title="Archive">
    <SEO title="Archive" />
    <section className="page">
      <div className="container text-white">
        <div className="content bg-black p-5">
          <div className="row">
            <div className="col-md-12">
              <h2 className="text-center">Green Hall Of Fame</h2>
              <iframe
                src="https://www.youtube.com/embed/h_0SWEdW9IE"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                width={1080}
                height={640}
                frameBorder={0}
                title="Green Hall of Fame"
              />
              <p className="mt-3">
                Video Link of Mark Joyous Being Inducted into the Green Hall of
                Fame
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container text-white">
        <div className="content bg-black p-5">
          <div className="row text-center mb-5">
            <h2>
              DO YOU OWN A PHOTO OF "HOME"? EARTH IMAGES AVAILABLE FOR FUN FUND
              RAISING $$
            </h2>
          </div>
          <div className="row mb-5">
            <div className="col-md-12">
              <p>
                "Earth Day is Every Day," but spring and summer often have more
                people interested in, and in contact with, Earth and Nature -
                especially with festivals, booths, events and so forth taking
                place everywhere. Make sure you have a full set of options when
                you do public presentations.
              </p>
              <p>
                <a
                  href="www.globalviewfoundation.org"
                  alt="GVF"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Global View Foundation
                </a>{" "}
                as the initiator of the EarthSeed’s "Planet in Every Classroom"
                Project ( www.earthseeds.org ) has wonderful gift items your
                organization can use for teaching tools or perhaps for
                fundraising yourselves. From Earth Flags, Posters, Globes (16"
                to 40" Eco-inflatables made from 2000 NASA satellite images and
                winner of 2005 Parent's Choice Award ) stickers and postcards,
                to even a full K - 6 Elementary Curriculum Kit available on Disk
                for only $25.
              </p>
              <p>
                Contact XXX for more information or to purchase products for
                your fun events of for a classroom, camp or church of your
                choice. Help raise awareness and global consciousness. Be a
                responsible "Crewmember" of Spaceship Earth and join in the
                EarthSeeds Project.
              </p>
              <p>RESOURCES Section</p>
              <p>
                <a
                  href="http://www.earthsticker.com/"
                  alt="GVF"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.earthsticker.com/
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container text-white">
        <div className="content bg-black p-5">
          <div className="row text-center mb-5">
            <div className="col-md-12">
              <h2>NEWSLETTERS = outreach</h2>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-12">
              <p>
                Just a brief note here to THANK YOU - for all you do -
                especially in these difficult crazy times - And to ask if you
                can put a brief blurb into your upcoming newsletter (s ) / list
                serves for April - May - June so that we can more readily
                connect gifted people to good projects - Here are main things to
                know: A & B PLEASE FWD to anyone interested in building some
                income & helping raise consciousness & Active Earth Care
                Communities here as Crew aboard Spaceship Earth.
              </p>
              <p>
                SALES = Newsletter rotations & also on OUR SALES BUTTON to
                ENCOURAGE OTHERS TO BUY IN: MAYBE PUT THIS TO HIGHLIGHT CIRCLE
                GRAPHIX & POSTERS ETC
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container text-white">
        <div className="content bg-black p-5">
          <div className="row text-center mb-5">
            <div className="col-md-12">
              <h2>EARTHSEEDS AMBASSADORS</h2>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-12">
              <p>
                The EarthSeeds Project, is an outreach program that provides
                Earth Image items for schools globally. They've sold many
                thousands of Earth Image items from Flags and Globes to
                Curriculum kits, and are looking for passionate, skilled
                person(s) to handle the next level of operations. This would
                include primarily working from home on phone and email, as well
                as training other EarthSeeds Ambassadors in school programs and
                outreach projects. This green opportunity is perfect for any
                self-motivated individuals, retired teachers, or even parents
                who need part-time work. Pay is based on Performance -
                Negotiable - with many special benefits, including travel and
                social fun. Contact:{" "}
                <a href="mailto:ssearth47@aol.com">ssearth47@aol.com</a> or call
                Mark at <a href="tel:7199643935">719-964-3935</a> or Pete at{" "}
                <a href="7192901000">719-290-1000</a> for more information or to
                schedule an interview. The website is currently undergoing a
                facelift but can be seen at:{" "}
                <a href="www.earthseeds.org">www.EarthSeeds.org</a>
              </p>
              <p>
                Anyone who helps promote the "Astronaut's View from Space" is an
                honorary EarthSeeds Ambassador. When you see an Earth postcard
                on someone's refrigerator in Boston, San Diego or Paris, when
                you find an inflatable Astronaut's Globe or a NASA photo Poster
                in a classroom, church or camp, when you see an Earth Flag
                flying somewhere - you know this is the work of EarthSeeds
                Ambassadors. Slowly but surely these folks are helping to shift
                consciousness on the planet towards Oneness and Unity - towards
                Peace and Eco-Logical Stability and Sustainable Living.
              </p>
              <p>
                If you'd like to learn more about how you can have fun as an
                EarthSeeds Ambassador and help the "Great Up-Wising" then read
                on FAQ'S? = "FREQUENTLY ASKED QUESTIONS" - ABOUT EARTHSEEDS
                AMBASSADORS WHAT'S AN EARTHSEED? WHAT ARE EARTHSEEDS?
                "EarthSeeds" is a code name for any idea, concept, thought,
                action or deed that can be planted in the Hearts and Minds of
                the "Crew of Spaceship Earth" to help build bridges towards a
                happier, healthier, greener, more balanced and sustainable home
                planet Earth. Earthseeds are often connected with the Image of
                Earth as seen from Space - The "Global Family Portrait" of us
                all together.
              </p>
              <p>
                Everyone is a "Crewmembers of Spaceship Earth" by virtue of the
                fact that they are living here on this lovely round blue ball we
                call "Home". Some of us have stepped up to be "EARTHSEEDS
                AMBASSADORS" - so all Children of Earth will see and realize
                that:
              </p>
              <p>
                "THE TIME HAS COME TO SEE.... WE ARE ALL ONE FAMILY." EarthSeeds
                Ambassadors are focused on building Unity and creating harmony.
                We're here to RE-MIND each other of our true "Common Ground" -
                Planet Earth. We always have much more in common than we do in
                differences. We're challenged with the task of coming together
                to live more green and sustainable. Soon.
              </p>
              <p>
                EARTH SEEDS AMBASSADORS INCLUDE ALL SORTS OF TRAVELERS; Like
                animals who gather stickers in their fur & unconsciously “plant”
                seeds as they travel = OUR EARTHSEEDS AMBASSADORS stop by
                Churches, Schools, Pubs & Libraries & Green Biz Nets & many
                other Community Forums for placing EARTH IMAGES & FLYING EARTH
                FLAGS for Future Forward Days = Our Ambassadors have joined in
                Fun in many ways: Ages 4 - 97 - so Join in the Fun!
              </p>
              <p>
                Younger Folks Traveling around the World Older Folks - Elder
                Hostels & Travelers Teachers & Musicians & Green Business
                Travelers Sports & Entertainment & Church & Spiritual Networkers
                & healers All are basically Invited & Self-Selected to Serve our
                Earthly Home in this way - awakening SSEarth Crew
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ArchivePage
